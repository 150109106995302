@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  cursor: default;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@layer base {
  /* Prevents up & down arrow on input type number fields */
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

  /* Shadcn Styles */
  :root {
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --border: 0 0% 89.8%;
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

/* Autofill color */
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

/* prevents tailwind forms border */
input[type='text']:focus {
  box-shadow: none;
}

.detail-field:not(:last-child) {
  @apply border-b border-gray-200;
}

.detail-column:not(:last-child) {
  @apply border-r;
}

/* clerk styles */
/* disable ability to create account from sign in screen */
.cl-footer {
  display: none;
}

/* remove sign in subtitle */
.cl-headerSubtitle {
  display: none;
}

/* change font to system font */
.cl-userButtonPopoverdCard .cl-card .cl-rootBox .cl-organizationSwitcherPopoverRootBox {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif !important;
}

/* disable user settings */
.cl-userButtonPopoverActionButton__manageAccount {
  display: none;
}

/* disable create organization */
.cl-organizationSwitcherPopoverActionButton__createOrganization {
  display: none;
}

/* disable manage organization */
.cl-organizationSwitcherPopoverActionButton__manageOrganization {
  display: none;
}

/* no preview for no org state */
.cl-userPreviewAvatarContainer {
  display: none;
}

/* spacing between org name and org photo in switcher */
.cl-organizationPreview {
  gap: 0.5rem;
}

.cl-organizationSwitcher-root {
  display: flex;
  justify-content: center;
}

.cl-organizationPreviewAvatarBox {
  background-color: #aefbac;
  border: none;
}

.cl-formButtonPrimary {
  background-color: #aefbac;
  color: #03162a;
  border: none;
}

.cl-formButtonPrimary:hover {
  background-color: #c7fcc5;
  color: #03162a;
  border: none;
}

.cl-formButtonPrimary:disabled {
  background-color: #c7fcc5;
  border: none;
}

.cl-formButtonReset {
  color: #03162a;
}

.cl-formButtonReset:hover {
  background: #c7fcc5;
}

.cl-internal-rp182n {
  background-color: #aefbac;
  color: #03162a;
  border: none;
}

.cl-internal-rp182n:hover {
  background-color: #c7fcc5;
  color: #03162a;
  border: none;
}

/* badge  */
.cl-badge {
  background-color: #c7fcc5;
  color: #03162a;
}

.cl-profileSectionPrimaryButton {
  color: #03162a;
}

.cl-profileSectionPrimaryButton:hover {
  background-color: #c7fcc5;
  color: #03162a;
}

/* disable ability to change org name */
.cl-formField__name {
  display: none;
}

/* profile picture image upload button*/
.cl-internal-1w85uqz {
  color: #03162a;
}

/* invite button */
.cl-membersPageInviteButton {
  background-color: #aefbac;
  color: #03162a;
  border: none;
}

.cl-membersPageInviteButton:hover {
  background-color: #c7fcc5;
  color: #03162a;
  border: none;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
